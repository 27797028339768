import React from 'react';

import { SEO } from '..';
import { External } from '../icons';

import style from './HomePage.module.less';

import logo from './assets/logo.png';
import epLogo from './assets/ep-logo.png';
import alabLogo from './assets/alab-logo.png';

export const HomePage = () => (
  <div className={style.layout}>
    <SEO />
    <img className={style.logo} alt="Eleven Products" src={logo} />
    <p>
      Eleven Products Sp. z o.o. <br />
      ul. Domki 14 <br />
      31-519 Kraków <br />
      VAT ID: PL6793208772
    </p>

    <h3>Our brands:</h3>

    <div className={style.product}>
      <p><a href="https://elevenpuzzles.com" target="_blank" className={style.productLogo}><img src={epLogo} alt="Eleven Puzzles" /></a></p>
      <p><span>Eleven Puzzles</span><br />Online Escape Rooms</p>
      <p><a href="https://elevenpuzzles.com" target="_blank">https://elevenpuzzles.com <External /></a></p>
    </div>

    <div className={style.product}>
      <p><a href="https://elevenalab.com" target="_blank" className={style.productLogo}><img src={alabLogo} alt="Eleven aLab" /></a></p>
      <p><span>Eleven aLab</span><br />Animal Behavior Evaluation Platform</p>
      <p><a href="https://elevenalab.com" target="_blank">https://elevenalab.com <External /></a></p>
    </div>
  </div>
);
